<template>
  <div
    class="p-headerSelector"
    @mouseleave="handleMouseleaveContainer"
    @mouseenter="handleMouseenterContainer"
  >
    <button
      class="p-headerSelector__button"
      data-icon="globe"
      aria-haspopup="true"
      :aria-expanded="expanded"
      aria-controls="language-selector-list"
      @click.stop="handleToggleList"
      v-text="currentLanguageDisplay"
    />
    <transition name="a-headerSelector__list">
      <ul
        id="language-selector-list"
        class="p-headerSelector__list"
        v-show="expanded"
        @click="handleClickList"
      >
        <li
          v-for="item in listItems"
          :key="item.locale"
          class="p-headerSelector__item"
          :class="{
            'p-headerSelector__item--selected': locale === item.locale,
          }"
        >
          <label class="p-headerSelector__control">
            <input
              type="radio"
              name="locale"
              class="p-headerSelector__input"
              :value="item.locale"
              v-model="locale"
            />
            <span v-text="item.label" />
          </label>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PHeaderLocaleSelector',
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    locale: {
      get() {
        return this.$i18n.locale
      },
      set(next) {
        this.$i18n.locale = next
      },
    },
    listItems() {
      return [
        {
          locale: 'ja',
          label: '日本語',
        },
        {
          locale: 'en',
          label: 'English',
        },
      ]
    },
    currentLanguageDisplay() {
      return this.listItems
        .find(({ locale }) => locale === this.locale)
        .locale.toUpperCase()
    },
  },
  methods: {
    handleToggleList() {
      this.expanded = !this.expanded
    },
    handleMouseleaveContainer() {
      if (this.expanded) {
        if (this.timer !== false) {
          clearTimeout(this.timer)
        }

        this.timer = setTimeout(() => {
          this.expanded = false
          this.timer = false
        }, 500)
      }
    },
    handleMouseenterContainer() {
      if (this.timer !== false) {
        clearTimeout(this.timer)
        this.timer = false
      }
    },
    handleClickList() {
      this.expanded = false
    },
    handleClickOutsideContainer() {
      this.expanded = false
    },
  },
  watch: {
    expanded(next) {
      if (next) {
        document.addEventListener('click', this.handleClickOutsideContainer)
      } else {
        document.removeEventListener('click', this.handleClickOutsideContainer)

        if (this.timer !== false) {
          clearTimeout(this.timer)
          this.timer = false
        }
      }
    },
    //多言語対応
    locale(next) {
      this.$i18n.locale = next
    },
  },
  beforeDestroy() {
    if (this.timer !== false) {
      clearTimeout(this.timer)
    }

    document.removeEventListener('click', this.handleClickOutsideContainer)
  },
}
</script>
