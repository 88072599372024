<template>
  <ul class="p-headerMobileSelector p-headerMobileSignageModeSelector">
    <li
      class="p-headerMobileSelector__item p-headerMobileSelector__item--selected p-headerMobileSignageModeSelector__item"
    >
      <span class="p-headerMobileSelector__control">
        <span class="p-headerMobileSelector__label" v-t="'標準'" />
      </span>
    </li>
    <li
      class="p-headerMobileSelector__item p-headerMobileSignageModeSelector__item p-headerMobileSignageModeSelector__item--tablet"
    >
      <app-link
        :to="{ name: 'ShopSignage', params, query: { type: 'tablet' } }"
        class="p-headerMobileSelector__control"
      >
        <span class="p-headerMobileSelector__label">
          {{ $t('サイネージモード.サイネージモード(タブレット)') }}
          <i18n
            class="p-headerMobileSignageModeSelector__small"
            path="(タブレット).サイネージモード(タブレット)"
          />
        </span>
      </app-link>
    </li>
    <li
      class="p-headerMobileSelector__item p-headerMobileSignageModeSelector__item p-headerMobileSignageModeSelector__item--mobile"
    >
      <app-link
        :to="{ name: 'ShopSignage', params, query: { type: 'mobile' } }"
        class="p-headerMobileSelector__control"
      >
        <span class="p-headerMobileSelector__label">
          {{ $t('サイネージモード.サイネージモード(スマートフォン)') }}
          <i18n
            class="p-headerMobileSignageModeSelector__small"
            path="(スマートフォン).サイネージモード(スマートフォン)"
          />
        </span>
      </app-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PHeaderMobileSignageModeSelector',
  computed: {
    params() {
      return {
        portalId: this.portalId,
        id: this.$route.params.id,
      }
    },
  },
}
</script>
