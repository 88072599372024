<template>
  <div
    class="p-headerSelector"
    @mouseleave="handleMouseleaveContainer"
    @mouseenter="handleMouseenterContainer"
  >
    <i18n
      tag="button"
      class="p-headerSelector__button"
      data-icon="dash-square"
      aria-haspopup="true"
      :aria-expanded="expanded"
      aria-controls="signage-mode-selector-list"
      @click.stop="handleToggleList"
      path="表示モード"
    />
    <transition name="a-headerSelector__list">
      <ul
        id="signage-mode-selector-list"
        class="p-headerSelector__list"
        v-show="expanded"
        @click="handleClickList"
      >
        <li class="p-headerSelector__item p-headerSelector__item--selected">
          <i18n class="p-headerSelector__control" path="標準" />
        </li>
        <li class="p-headerSelector__item">
          <app-link
            class="p-headerSelector__control"
            :to="{ name: 'ShopSignage', params, query: { type: 'tablet' } }"
            v-text="
              `${$t('サイネージモード.サイネージモード(タブレット)')}${$t(
                '(タブレット).サイネージモード(タブレット)'
              )}`
            "
          />
        </li>
        <li class="p-headerSelector__item">
          <app-link
            class="p-headerSelector__control"
            :to="{ name: 'ShopSignage', params, query: { type: 'mobile' } }"
            v-text="
              `${$t('サイネージモード.サイネージモード(スマートフォン)')}${$t(
                '(スマートフォン).サイネージモード(スマートフォン)'
              )}`
            "
          />
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PHeaderSignageModeSelector',
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    params() {
      return {
        portalId: this.portalId,
        id: this.$route.params.id,
      }
    },
  },
  methods: {
    handleToggleList() {
      this.expanded = !this.expanded
    },
    handleMouseleaveContainer() {
      if (this.expanded) {
        if (this.timer !== false) {
          clearTimeout(this.timer)
        }

        this.timer = setTimeout(() => {
          this.expanded = false
          this.timer = false
        }, 500)
      }
    },
    handleMouseenterContainer() {
      if (this.timer !== false) {
        clearTimeout(this.timer)
        this.timer = false
      }
    },
    handleClickList() {
      this.expanded = false
    },
    handleClickOutsideContainer() {
      this.expanded = false
    },
  },
  watch: {
    expanded(next) {
      if (next) {
        document.addEventListener('click', this.handleClickOutsideContainer)
      } else {
        document.removeEventListener('click', this.handleClickOutsideContainer)

        if (this.timer !== false) {
          clearTimeout(this.timer)
          this.timer = false
        }
      }
    },
    //多言語対応
    locale(next) {
      this.$i18n.locale = next
    },
  },
  beforeDestroy() {
    if (this.timer !== false) {
      clearTimeout(this.timer)
    }

    document.removeEventListener('click', this.handleClickOutsideContainer)
  },
}
</script>
