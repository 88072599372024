<template>
  <div class="p-headerControls p-headerControls--pc">
    <template v-if="isLoggedIn">
      <CTooltip class="p-headerControls__avatar">
        <template slot="target">
          <span class="p-headerAvatar">
            <img
              src="/img/common/ico_avatar.jpg"
              alt=""
              width="30"
              height="30"
            />
          </span>
        </template>
        <template slot="content">
          <span v-text="username" />
        </template>
      </CTooltip>
      <button
        class="p-headerControls__button"
        v-t="'ログアウト'"
        @click="handleClickLogoutButton"
      />
    </template>
    <template v-else>
      <app-link
        class="p-headerControls__button"
        :to="{
          name: 'Login',
          params: { portalId },
          query: { redirect: $route.fullPath },
        }"
        v-t="'ログイン'"
      />
    </template>
  </div>
</template>

<script>
import CTooltip from '@/components/common/CTooltip.vue'
import { cognitoSignOut } from '@/assets/modules/cognito'

export default {
  name: 'PHeaderControls',
  components: { CTooltip },
  computed: {
    username() {
      return this.$store.getters['auth/username']
    },
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
  },
  methods: {
    async handleClickLogoutButton() {
      await cognitoSignOut()

      this.$router.push({
        name: 'Index',
        params: {
          portalId: this.portalId,
        },
        query: {
          status: 'signout',
        },
      })
    },
  },
}
</script>
