<template>
  <div class="c-tooltip">
    <app-link
      class="c-tooltip__target"
      ref="target"
      :aria-describedby="balloonId"
      @mouseenter="handleMounseenter"
      @mouseleave="hideTooltip"
      @focus="showTooltip"
      @blur="hideTooltip"
    >
      <slot name="target" />
    </app-link>
    <transition
      name="a-tooltip"
      @enter="setBalloonStyle"
      @after-leave="resetBallonStyle"
    >
      <p
        v-show="show"
        ref="balloon"
        :id="balloonId"
        class="c-tooltip__balloon"
        :style="conputedBalloonStyle"
      >
        <slot name="content" />
      </p>
    </transition>
  </div>
</template>

<script>
import { KeyCode } from '@/assets/modules/wai-aria/util'
export default {
  name: 'CTooltip',
  data() {
    return {
      show: false,
      timer: false,
      balloonStyle: {
        left: '',
        width: '',
        whiteSpace: '',
        transformOrigin: 'top left',
      },
    }
  },
  computed: {
    balloonId() {
      const key = () => Math.random().toString(36).replace('0.', '')
      return '__tooltip_id__' + (this._uid || key())
    },
    target() {
      return this.$refs.target
    },
    balloon() {
      return this.$refs.balloon
    },
    conputedBalloonStyle() {
      return this.balloonStyle
    },
  },
  methods: {
    clearTimer() {
      if (this.timer !== false) {
        clearTimeout(this.timer)
        this.tiemr = false
      }
    },
    showTooltip() {
      this.clearTimer()
      this.show = true
      this.attachKeyEvent()
    },
    hideTooltip() {
      this.clearTimer()
      this.show = false
      this.removeKeyEvent()
    },
    handleMounseenter() {
      this.clearTimer()
      this.timer = setTimeout(this.showTooltip, 300)
    },
    attachKeyEvent() {
      document.addEventListener('keydown', this.handleEscapeKeyDown)
    },
    removeKeyEvent() {
      document.removeEventListener('keydown', this.handleEscapeKeyDown)
    },
    handleEscapeKeyDown(event) {
      if (event.keyCode === KeyCode.Esc) {
        this.hideTooltip()
      }
    },
    setBalloonStyle() {
      const targetRect = this.target.getBoundingClientRect()
      const targetWidth = this.target.offsetWidth
      const targetLeftPos = Math.ceil(targetRect.left) + window.pageXOffset
      const balloonLeft = Math.ceil(targetWidth * 0.8 + 1)
      const balloonLeftPos = targetLeftPos + balloonLeft
      const pageWidth = document.documentElement.offsetWidth
      const balloonWidth = this.balloon.offsetWidth

      if (balloonWidth > pageWidth) {
        this.balloonStyle.width = `${pageWidth}px`
        this.balloonStyle.left = `${targetLeftPos * -1}px`
        this.balloonStyle.whiteSpace = `pre-wrap`
        return
      }

      const overflow = balloonLeftPos + balloonWidth - pageWidth
      if (overflow > 0) {
        const left = balloonLeft - overflow
        this.balloonStyle.left = `${left}px`
        this.balloonStyle.transformOrigin = `${left * -1}px top`
      }
    },
    resetBallonStyle() {
      this.balloonStyle = {
        left: '',
        width: '',
        whiteSpace: '',
        transformOrigin: '',
      }
    },
  },
}
</script>
