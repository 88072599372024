<template>
  <ul class="p-headerMobileLinks" v-if="links.length">
    <li
      v-for="(link, index) in links"
      :key="index"
      class="p-headerMobileLinks__item"
    >
      <app-link
        class="p-headerMobileLinks__link"
        :to="link.to"
        v-text="link.label"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PHeaderMobileLinks',
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
