<template>
  <ul class="p-headerMobileSelector">
    <li
      v-for="item in localItems"
      :key="item.locale"
      class="p-headerMobileSelector__item"
      :class="{
        'p-headerMobileSelector__item--selected': locale === item.locale,
      }"
    >
      <label class="p-headerMobileSelector__control">
        <input
          type="radio"
          name="locale"
          :value="item.locale"
          v-model="locale"
          class="p-headerMobileSelector__input"
        />
        <span class="p-headerMobileSelector__label" v-text="item.label" />
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PHeaderMobileLocaleSelector',
  computed: {
    locale: {
      get() {
        return this.$i18n.locale
      },
      set(next) {
        this.$i18n.locale = next
      },
    },
    localItems() {
      return [
        {
          locale: 'ja',
          label: '日本語',
        },
        {
          locale: 'en',
          label: 'English',
        },
      ]
    },
  },
}
</script>
