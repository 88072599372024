<template>
  <dl class="p-headerMobileSection">
    <p class="p-headerMobileSection__title" :data-icon="icon">
      <slot name="title" />
    </p>
    <div class="p-headerMobileSection__content">
      <slot />
    </div>
  </dl>
</template>

<script>
export default {
  name: 'PHeaderMobileSection',
  props: {
    icon: {
      type: String,
    },
  },
}
</script>
