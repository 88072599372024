<template>
  <transition
    name="a-headerMobileMenu"
    @before-enter="enableModal"
    @after-enter="body"
    @before-leave="disableModal"
    appear
  >
    <div
      v-show="opened"
      class="p-headerMobileMenu"
      id="mobile-menu"
      @click="closeMenu"
    >
      <div
        ref="body"
        class="p-headerMobileMenu__body"
        tabindex="-1"
        @click.stop
      >
        <template v-if="isLoggedIn">
          <ul class="p-headerMobileLinks">
            <li class="p-headerMobileLinks__item">
              <app-link
                class="p-headerMobileLinks__link"
                @click="handleClickLogoutButton"
                v-t="'ログアウト'"
              />
            </li>
          </ul>
        </template>
        <template v-else>
          <ul class="p-headerMobileLinks">
            <li class="p-headerMobileLinks__item">
              <app-link
                class="p-headerMobileLinks__link"
                :to="{
                  name: 'Login',
                  params: { portalId },
                  query: { redirect: $route.fullPath },
                }"
                v-t="'ログイン'"
              />
            </li>
          </ul>
        </template>

        <PHeaderMobileLinks class="p-headerMobileMenu__links" :links="links" />

        <PHeaderMobileSection
          v-if="isShowLocaleSelector"
          class="p-headerMobileMenu__section"
          icon="globe"
        >
          <template #title>{{ $t('表示言語') }}</template>
          <PHeaderMobileLocaleSelector />
        </PHeaderMobileSection>

        <PHeaderMobileSection
          v-if="isShowSignageModeSelector"
          class="p-headerMobileMenu__section"
          icon="dash-square"
        >
          <template #title>{{ $t('表示モード') }}</template>
          <PHeaderMobileSignageModeSelector />
        </PHeaderMobileSection>
      </div>
    </div>
  </transition>
</template>

<script>
import PHeaderMobileLinks from '@/components/common/PHeaderMobileLinks.vue'
import PHeaderMobileSection from '@/components/common/PHeaderMobileSection.vue'
import PHeaderMobileLocaleSelector from '@/components/common/PHeaderMobileLocaleSelector.vue'
import PHeaderMobileSignageModeSelector from '@/components/common/PHeaderMobileSignageModeSelector.vue'
import {
  focusFirstDescendant,
  focusLastDescendant,
  IgnoreUtilFocusChanges,
} from '@/assets/modules/wai-aria/focusTrap/util'
import { KeyCode } from '@/assets/modules/wai-aria/util'
import { cognitoSignOut } from '@/assets/modules/cognito'

export default {
  name: 'PHeaderMobileMenu',
  inject: ['setModal'],
  components: {
    PHeaderMobileLinks,
    PHeaderMobileSection,
    PHeaderMobileLocaleSelector,
    PHeaderMobileSignageModeSelector,
  },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      scrollY: null,
    }
  },
  computed: {
    isShowLocaleSelector() {
      return !(
        (this.$route.name === 'ShopDetail' ||
          this.$route.name === 'SensorDetail') &&
        !this.$store.getters['detail/hasEnglishPage']
      )
    },
    isShowSignageModeSelector() {
      return this.$route.name === 'ShopDetail'
    },
    links() {
      return [
        {
          to: { name: 'Faq', params: { portalId: this.portalId } },
          label: this.$t('よくあるご質問'),
        },
      ]
    },
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
  },
  beforeDestroy() {
    this.disableModal()
  },
  methods: {
    async handleClickLogoutButton() {
      await cognitoSignOut()

      this.$router.push({
        name: 'Index',
        params: {
          portalId: this.portalId,
        },
        query: {
          status: 'signout',
        },
      })
    },
    enableModal() {
      this.setModal(true)
      this.attachFocusTrap()
      this.attachKeyEvent()
    },
    body() {
      this.beforeFocus = document.activeElement
      this.$refs.body.focus()
    },
    disableModal() {
      this.setModal(false)
      this.removeFocusTrap()
      this.removeKeyEvent()
      this.beforeFocus?.focus()
    },
    attachFocusTrap() {
      document.addEventListener('focus', this.focusTrap, true)
    },
    removeFocusTrap() {
      document.removeEventListener('focus', this.focusTrap, true)
    },
    focusTrap(event) {
      if (IgnoreUtilFocusChanges) return

      const target = event.target
      const dialog = this.$refs.body

      if (dialog.contains(target)) {
        this.lastFocus = target
      } else {
        focusFirstDescendant(dialog)
        if (this.lastFocus === document.activeElement) {
          focusLastDescendant(dialog)
        }
        this.lastFocus = document.activeElement
      }
    },
    attachKeyEvent() {
      document.addEventListener('keydown', this.handleEscapeKeyDown)
    },
    removeKeyEvent() {
      document.removeEventListener('keydown', this.handleEscapeKeyDown)
    },
    handleEscapeKeyDown(event) {
      if (event.keyCode === KeyCode.Esc) {
        this.closeModal()
      }
    },
    closeMenu() {
      this.$emit('update:opened', false)
    },
  },
}
</script>
