<template>
  <header ref="header" class="p-header">
    <div class="p-header__top">
      <p
        class="p-header__topInner"
        v-t="
          `${portalId}.おみせのトラストは、お店のトラスト情報（衛生対策）を見える化する実証実験です。`
        "
      />
    </div>
    <nav class="p-header__body">
      <h1>
        <app-link :to="{ name: 'Index', params: { portalId } }">
          <PSiteLogo class="p-header__logo" />
        </app-link>
      </h1>
      <div class="p-header__items">
        <PHeaderSignageModeSelector
          v-if="isShowSignageModeSelector"
          class="p-header__item p-header__item--pc"
        />
        <PHeaderLocaleSelector
          v-if="isShowLocaleSelector"
          class="p-header__item p-header__item--pc"
        />
        <app-link
          class="p-header__item p-header__link p-header__item--pc"
          :to="{ name: 'Faq', params: { portalId } }"
          v-t="'よくあるご質問'"
        />
        <PHeaderControls class="p-header__item p-header__controls" />
        <app-link
          class="p-header__item"
          href="https://www.hitachi.co.jp/"
          target="_blank"
        >
          <img
            class="p-header__item__companyLogo"
            src="/img/common/hitachi_logo.png"
            alt=""
            width="115"
          />
        </app-link>
        <PHeaderMobileMenuToggle
          class="p-header__mobileMenuToggle"
          :opened="mobileMenuOpened"
          @click="handleClickMenuToggle"
        />
      </div>
    </nav>
    <PHeaderMobileMenu
      ref="mobileMenu"
      :opened.sync="mobileMenuOpened"
      :style="{ paddingTop: `${headerHeight}px` }"
    />
  </header>
</template>
<script>
import AppLink from '../AppLink.vue'
import PSiteLogo from '@/components/common/PSiteLogo.vue'
import PHeaderSignageModeSelector from '@/components/common/PHeaderSignageModeSelector.vue'
import PHeaderLocaleSelector from '@/components/common/PHeaderLocaleSelector.vue'
import PHeaderControls from '@/components/common/PHeaderControls.vue'
import PHeaderMobileMenuToggle from '@/components/common/PHeaderMobileMenuToggle.vue'
import PHeaderMobileMenu from '@/components/common/PHeaderMobileMenu.vue'

export default {
  name: 'PHeader',
  components: {
    AppLink,
    PSiteLogo,
    PHeaderSignageModeSelector,
    PHeaderLocaleSelector,
    PHeaderControls,
    PHeaderMobileMenuToggle,
    PHeaderMobileMenu,
  },
  data() {
    return {
      isMobile: false,
      mobileMenuOpened: false,
      headerHeight: 0,
    }
  },
  mounted() {
    this.setHeaderHeight()
    this.setIsMobile()
    window.addEventListener('resize', this.handleWindowResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  watch: {
    '$i18n.locale'() {
      this.$nextTick(() => {
        this.setHeaderHeight()
      })
    },
    portalId() {
      this.$nextTick(() => {
        this.setHeaderHeight()
        this.mobileMenuOpened = false
      })
    },
    $route() {
      this.mobileMenuOpened = false
    },
    isMobile() {
      this.mobileMenuOpened = false
    },
  },
  computed: {
    isShowLocaleSelector() {
      return !(
        (this.$route.name === 'ShopDetail' ||
          this.$route.name === 'SensorDetail') &&
        !this.$store.getters['detail/hasEnglishPage']
      )
    },
    isShowSignageModeSelector() {
      return this.$route.name === 'ShopDetail'
    },
  },
  methods: {
    handleClickMenuToggle() {
      this.mobileMenuOpened = !this.mobileMenuOpened
    },
    handleWindowResize() {
      this.setHeaderHeight()
      this.setIsMobile()
    },
    setHeaderHeight() {
      this.headerHeight = this.$refs.header.clientHeight
    },
    setIsMobile() {
      this.isMobile = matchMedia('screen and (max-width: 799px)').matches
    },
  },
}
</script>
