<template>
  <button
    class="p-headerMobileMenuToggle"
    :class="{ 'p-headerMobileMenuToggle--opened': opened }"
    @click="$emit('click')"
    :aria-expanded="opened"
    aria-controls="mobile-menu"
  >
    <span class="p-headerMobileMenuToggle__line"></span>
    <span class="p-headerMobileMenuToggle__line"></span>
    <span class="p-headerMobileMenuToggle__line"></span>
  </button>
</template>

<script>
export default {
  name: 'PHeaderMobileMenuToggle',
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
